import Plyr from 'plyr/dist/plyr.polyfilled'
import { debounce } from 'lodash-es'

const $players = document.querySelectorAll('[data-player]')

const init = async () => {
  if ($players && $players.length > 0) {
    lazyloadPlayer()

    window.addEventListener('scroll', lazyloadPlayer)
  }
}

const lazyloadPlayer = debounce(() => {
  const options = {
    rootMargin: '100px 0px',
    threshold: 0.01
  }

  $players.forEach(($player) => {
    if (!$player.getAttribute('data-plyr-fancybox')) {
      if ($player.getAttribute('data-player-hero')) {
        setupPlayer($player)
      } else {
        if (!('IntersectionObserver' in window)) {
          setupPlayer($player)
        } else {
          const observer = new IntersectionObserver(handleIntersection, options)
          observer.observe($player)
        }
      }
    }
  })
})

const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target)
      setupPlayer(entry.target)
    }
  })
}

const setupPlayer = ($player) => {
  const $plyrOptions = JSON.parse($player.dataset.plyrConfig)

  if ($plyrOptions.autoplay === 1 || $player.dataset.playerHero === '') {
    const plyr = new Plyr($player)

    plyr.on('ready', () => {
      plyr.muted = true
      plyr.play()

    })
  } else {
    Plyr.setup('[data-player]')
  }
}

init()
